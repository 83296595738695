import React from "react";
import Content from "../components/content";
import Layout from "../components/layout";
import Articles from "../components/post";

const BlogPage = () => {

    return (
        <Layout>
            <Content>
                <h1>Articles</h1>
                <Articles />
            </Content>
        </Layout>
        )
};

export default BlogPage;