import React from "react";
import articlesStyles from "./post.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

const Articles = ({cardItems, cardSize}) => {

    const data = useStaticQuery (graphql`
    query {
        allContentfulBlogPosts (sort: { fields: date, order: DESC}){
            edges{
                node {
                    title
                    slug
                    date(formatString:"MMMM DD, YYYY")
                    categories {
                        name
                    }
                    headerImage {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }
        }
    }
    `)

    let posts = data.allContentfulBlogPosts.edges;
    if (cardItems == 2) {
        posts = posts.slice(0, cardItems)
    } 

    return (
        <div className={articlesStyles.container}>
            <div>
                <ol className={articlesStyles.cards}>
                    { posts.map((edge) => {
                        return (
                            <li className={(cardSize == 2) ? articlesStyles.cardItemHome : articlesStyles.cardItem}>
                                <div className={articlesStyles.card}>
                                    <Link className={articlesStyles.link} to={`/blog/${edge.node.slug}`}>
                                        <div className={articlesStyles.postImage}>
                                            <img className={articlesStyles.image} src={edge.node.headerImage.file.url} alt={edge.node.headerImage.file.fileName} />
                                        </div>
                                        <div className={articlesStyles.postHeader}>
                                            <p className={articlesStyles.category}>{edge.node.categories[0].name}</p>
                                            <h2>{edge.node.title}</h2>
                                            <p>{edge.node.date}</p>
                                        </div>
                                    </Link>
                                </div>
                            </li>
                        )
                    })}
                </ol>
            </div>
        </div>
    )
}

export default Articles;