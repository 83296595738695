import React from "react";
import { Link } from "gatsby";
import contentStyles from './content.module.scss';

const Content = (props) => {
    return (
        <div className={contentStyles.container}>
            {props.children}
        </div>
    )
}

export default Content;